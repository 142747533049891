<template>
  <div class="about">
    <div class="header">
      <span class="dot">●</span>
      <span class="route-name">兑换码兑换</span>
    </div>
    <div v-if="errorMsg" class="center error">
      {{ errorMsg }}
    </div>
    <div class="center">
      <div v-if="checkStep">
        <span class="color_blue">[卡号]</span><br>
        {{ accessCode }} <br>
        <span class="color_green">[用户名]</span><br>
        {{ checkUserData.userName }} <br>
        <span class="color_green">[最后登录时间]</span><br>
        {{ checkUserData.lastPlayDate }} <br>
        <span class="color_green">[游戏局数]</span><br>
        {{ checkUserData.playCount }} <br>

        <div class="caution">※账号确认无误后，请点击「确认提交」</div>
      </div>
      <div v-if="!checkStep">
        <span class="color_blue">[卡片通行码]</span><br>
        <input v-model="accessCode" placeholder="卡片通行码"><br><br>
      </div>
      <span class="color_blue">[兑换码]</span><br>
      <input v-model="redeemCode" placeholder="兑换码"><br><br>
      <button v-if="!checkStep" v-on:click="check">查询</button>

      <button v-if="checkStep" v-on:click="submit">确认提交</button>
      <button v-if="checkStep" v-on:click="back">重新输入</button>

      <div class="caution">※实际的通行码与卡片上印刷的通行码可能不同。<br>
        请在游戏登录时选择「确认通行码」来确认。<br>
        填写通行码时请不要输入-（横杠）或空格等连字符<br>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Redeem',
  components: {},
  data() {
    return {
      errorMsg: null,
      accessCode: '',
      redeemCode: '',
      checkStep: false,
      checkUserData: null
    }
  },
  methods: {
    back() {
      this.accessCode = ''
      this.checkUserData = null
      this.checkStep = false
    },
    check() {
      this.axios.get('/api/redeem/dxProfile', {
        params: {
          accessCode: this.accessCode,
        }
      }).then(resp => {
        this.checkStep = true
        this.checkUserData = resp.data.data
      }).catch(error => {
        if (error.response?.data?.message) {
          this.errorMsg = error.response.data.message
        } else {
          this.errorMsg = error.message
        }
      })
    },
    submit() {
      this.errorMsg = null
      this.axios.post('/api/redeem/use', null, {
        params: {
          accessCode: this.accessCode,
          code: this.redeemCode
        }
      }).then(resp => {
        this.errorMsg = resp.data.message
      }).catch(error => {
        if (error.response?.data?.message) {
          this.errorMsg = error.response.data.message
        } else {
          this.errorMsg = error.message
        }
      })
    }
  }
}
</script>
