<template>
  <div class="about">
    <div class="header">
      <span class="dot">●</span>
      <span class="route-name">查询DX PASS状态</span>
    </div>
    <div class="center error" v-if="errorMsg">
      {{errorMsg}}
    </div>
    <div class="center">
      <span class="color_blue">[卡片通行码]</span><br>
      <input placeholder="卡片通行码" v-model="accessCode"><br><br>
      <button v-on:click="submit">提交</button>

      <div class="caution">※实际的通行码与卡片上印刷的通行码可能不同。<br>
        请在游戏登录时选择「确认通行码」来确认。<br>
        填写通行码时请不要输入-（横杠）或空格等连字符<br>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'CheckPass',
  components: {
  },
  data() {
    return {
      errorMsg: null,
      accessCode: ''
    }
  },
  methods: {
    submit() {
      this.errorMsg = null
      this.axios.get('/api/redeem/dxPass', { params: {
        accessCode: this.accessCode
        }}).then(resp => {
          const data = resp.data.data
        if (data.endTime) {
          let msg = "DX PASS: "
          if (data.passType == 6) {
            msg = msg + "FREEDOM PASS "
          } else if (data.passType == 4) {
            msg = msg + "GOLD PASS "
          }
          msg = msg + "有效期至：" + data.endTime
          this.errorMsg = msg
        } else {
          this.errorMsg = resp.data.message
        }
      }).catch(error => {
        if (error.response?.data?.message) {
          this.errorMsg = error.response.data.message
        } else {
          this.errorMsg = error.message
        }
      })
    }
  }
}
</script>
